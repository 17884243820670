import { cleanIsSoldToSelected } from "@/utils/soldToUtils";
import { eventProvider, sendErrorAnalytics, sendEventAnalytics } from "@mercury/common";
import OktaAuth, { UserClaims } from "@okta/okta-auth-js";
import { useAuth } from "@okta/okta-vue";

export const oktaConfig = {
  oidc: {
    // clientId: "0oadn2bi7tNQvLUzt5d7", //Okta Reply for PoC
    // issuer: "https://dev-46470584.okta.com/oauth2/default", //Okta Reply for PoC
    clientId: window.globalEnvVariables?.OKTA_CLIENT_ID,
    //issuer DEV VF Okta
    //Okta with Akamai --> account-vfportal-emea-dev.vfc.com
    //Okta without Akamai --> vfdev.oktapreview.com
    issuer: window.globalEnvVariables?.OKTA_ISSUER,
    redirectUri: window.globalEnvVariables?.OKTA_REDIRECT_LINK,
    scopes: ["openid", "profile", "email", "okta.myAccount.password.manage"],
    pkce: true,
    useInteractionCodeFlow: true,
  },
};

//logout the user
export const logout = async (
  eventProviderInstance?: ReturnType<typeof eventProvider.getEventProvider>,
) => {
  const auth = useAuth();
  await auth?.signOut({ revokeAccessToken: true, revokeRefreshToken: true });
  //redirect to the login page is automatically implemented by the okta-vue library

  //clean user info --> DONE IN App.vue OF THE SHELL APP
  // (eventProviderInstance || eventProvider.getEventProvider())?.emit(
  //   eventProvider.EventChannel.customerInfo,
  //   undefined,
  // );

  //clean sold-to selection session and local storage
  cleanIsSoldToSelected();
};

export const getAuthToken = (auth?: OktaAuth): string => {
  if (!auth) {
    auth = useAuth();
  }
  return auth?.getAccessToken() || "";
};

export const getUserInfo = (auth?: OktaAuth): Promise<UserClaims> => {
  if (!auth) {
    auth = useAuth();
  }
  // console.log("auth", { auth });

  return auth?.getUser() || {};
};

export const changePasswordAfterLogin = (
  password: string,
  soldToId: string,
  auth?: OktaAuth,
  eventProviderInstance?: eventProvider.Context,
) => {
  if (!auth) {
    auth = useAuth();
  }
  if (!eventProviderInstance) {
    eventProviderInstance = eventProvider.getEventProvider();
  }

  const accessToken = getAuthToken(auth);
  const account = auth?.myaccount;

  if (account && accessToken && eventProviderInstance) {
    eventProviderInstance.emit(eventProvider.EventChannel.changePasswordPostLoginInfo, {
      isLoading: true,
      isLoaded: false,
      success: false,
      errors: [],
    });

    account
      .updatePassword({
        accessToken: accessToken,
        payload: {
          profile: {
            password: password,
          },
        },
      })
      .then((passwordTransaction) => {
        console.log("updatePassword success", passwordTransaction);

        //analytics
        sendEventAnalytics({
          id: "passwordChange",
        });

        //alert BFF that password has been changed in order to remove the password expiring alert if existing
        eventProviderInstance.emit(eventProvider.EventChannel.deleteNotification, {
          typeUpdate: "expiring_password",
          soldToId: soldToId,
        });

        eventProviderInstance.emit(eventProvider.EventChannel.changePasswordPostLoginInfo, {
          isLoading: false,
          isLoaded: true,
          success: true,
          errors: [],
        });
      })
      .catch((err) => {
        const errors = err.errorCauses?.map((cause: { errorSummary: string }) => {
          const errorSummary = cause?.errorSummary || "";
          if (errorSummary.includes("Password has been used too recently")) {
            return "login_security.okta_password_error_used_recently";
          } else if (errorSummary.includes("Password requirements")) {
            return "login_security.okta_password_error_requirements";
          } else if (
            errorSummary.includes("This password was found in a list of commonly used passwords.")
          ) {
            return "login_security.okta_password_error_commonly_used";
          } else {
            return "login_security.okta_password_error_generic";
          }
        });
        console.error("updatePassword error", {
          err,
          errors,
        });
        eventProviderInstance.emit(eventProvider.EventChannel.changePasswordPostLoginInfo, {
          isLoading: false,
          isLoaded: true,
          success: false,
          errors,
        });

        //analytics
        sendErrorAnalytics({
          description: "Error updating the user password",
          techInfo: err,
        });
      });
  }
};

export const getPasswordLastUpdated = (
  auth?: OktaAuth,
  eventProviderInstance?: eventProvider.Context,
) => {
  if (!auth) {
    auth = useAuth();
  }
  if (!eventProviderInstance) {
    eventProviderInstance = eventProvider.getEventProvider();
  }

  const account = auth?.myaccount;

  if (account && eventProviderInstance) {
    eventProviderInstance.emit(eventProvider.EventChannel.passwordLastUpdatedInfo, {
      isLoading: true,
      lastUpdated: "",
    });

    account
      .getPassword()
      .then((passwordTransaction) => {
        const lastUpdated = passwordTransaction?.lastUpdated;
        console.log("Password last updated", { lastUpdated });
        eventProviderInstance.emit(eventProvider.EventChannel.passwordLastUpdatedInfo, {
          isLoading: false,
          lastUpdated,
        });
      })
      .catch((err) => {
        console.error("getPasswordLastUpdated error", {
          err,
        });

        //analytics
        sendErrorAnalytics({
          description: "Error getting the time of the last password change",
          techInfo: err,
        });

        if (err.xhr.status === 401) {
          //if user is unauthorized --> logout
          logout();
        }

        eventProviderInstance.emit(eventProvider.EventChannel.passwordLastUpdatedInfo, {
          isLoading: false,
          lastUpdated: "",
        });
      });
  }
};
