import { router } from "@/main";
import { ROUTING } from "@/routing/routes";
import { CustomerGeneralities } from "@/service/UserModel";
import { setSelectedSoldTo } from "@/service/UserService";
import { eventProvider } from "@mercury/common";

export const IS_SOLDTO_SELECTED = "isSoldToSelected";
export const LAST_SELECTED_SOLDTO = "lastSelectedSoldto";

export const setLastSelectedSoldTo = (
  selectedSoldToId: string,
  redirectToHomepage: boolean = true,
) => {
  if (!selectedSoldToId) {
    console.error("setLastSelectedSoldTo - selectedSoldToId is not present", { selectedSoldToId });
    return;
  }

  const eventProviderInstance = eventProvider.getEventProvider();
  const soldToList = eventProviderInstance.get(eventProvider.EventChannel.soldToList);

  if (soldToList?.[0]?.lastLogin !== selectedSoldToId) {
    //update sold-to selection in BE
    //if this request returns error --> go forward as well
    setSelectedSoldTo({ soldToId: selectedSoldToId });
  }

  if (redirectToHomepage) {
    router.push(ROUTING.dashboard.url);
  }

  handleSuccessSelectSoldTo(eventProviderInstance, selectedSoldToId, soldToList);
};

const handleSuccessSelectSoldTo = (
  eventProviderInstance: ReturnType<typeof eventProvider.getEventProvider>,
  selectedSoldToId: string,
  soldToList: CustomerGeneralities[],
) => {
  //update sold-to list in event provider
  soldToList?.forEach((soldTo: CustomerGeneralities) => {
    soldTo.lastLogin = selectedSoldToId;
  });
  eventProviderInstance?.emit(eventProvider.EventChannel.soldToList, soldToList);

  const selectedSoldTo = soldToList?.find(
    (soldTo: CustomerGeneralities) => soldTo.customerId === selectedSoldToId,
  );

  //update sold-to selection in event provider
  eventProviderInstance?.emit(eventProvider.EventChannel.selectedSoldTo, selectedSoldTo);

  //update session storage
  setIsSoldToSelected();
};

export const getLastSelectedSoldTo = (
  soldToList: CustomerGeneralities[] | undefined,
): CustomerGeneralities | undefined => {
  if (!soldToList || soldToList.length === 0) {
    return undefined;
  }

  let lastSoldTo: CustomerGeneralities | undefined = soldToList?.find(
    (soldTo) => soldTo.customerId === soldTo.lastLogin,
  );
  if (!lastSoldTo) {
    //if not last selection (ex. first login) --> get the first soldto, they should be already sorted alphabetically by legal name
    lastSoldTo = soldToList[0];
  }

  return lastSoldTo;
};

export const setIsSoldToSelected = () => {
  const username = getUsername();
  if (!username) {
    return;
  }

  const lastSelectedSoldToMap = JSON.parse(sessionStorage.getItem(IS_SOLDTO_SELECTED) || "{}");
  lastSelectedSoldToMap[username] = "true";

  sessionStorage.setItem(IS_SOLDTO_SELECTED, JSON.stringify(lastSelectedSoldToMap));
};

export const getIsSoldToSelected = (): boolean => {
  const username = getUsername();
  if (!username) {
    return false;
  }

  const lastSelectedSoldToMap = JSON.parse(sessionStorage.getItem(IS_SOLDTO_SELECTED) || "{}");
  return lastSelectedSoldToMap[username] === "true";
};

export const cleanIsSoldToSelected = () => {
  sessionStorage.removeItem(IS_SOLDTO_SELECTED);
};

const getUsername = (): string | undefined => {
  const userInfoOkta = eventProvider
    .getEventProvider()
    .get(eventProvider.EventChannel.userInfoOkta);

  let username = userInfoOkta?.preferred_username;
  if (!username) {
    let oktaTokenStorageJson = undefined;
    try {
      const oktaTokenStorageString = localStorage.getItem("okta-token-storage");
      if (oktaTokenStorageString) {
        oktaTokenStorageJson = JSON.parse(oktaTokenStorageString || "{}");
      }
    } catch (error) { }

    username = oktaTokenStorageJson?.idToken?.claims?.preferred_username;
  }

  return username || "";
};

export const selectLastSelectedSoldTo = (soldTos: CustomerGeneralities[]) => {
  const lastSoldTo = getLastSelectedSoldTo(soldTos);
  eventProvider.getEventProvider()?.emit(eventProvider.EventChannel.selectedSoldTo, lastSoldTo);
};

export const acceptTermsInEventProvider = (
  soldToId: string,
  changeSelectedSoldTo: boolean = true,
) => {
  const eventProviderInstance = eventProvider.getEventProvider();
  const soldToList = eventProviderInstance.get(eventProvider.EventChannel.soldToList);
  const userInfoOkta = eventProviderInstance.get(eventProvider.EventChannel.userInfoOkta);

  const soldToIndex = soldToList?.findIndex((soldTo) => soldTo.customerId === soldToId);
  if (soldToIndex >= 0) {
    soldToList[soldToIndex].termsAndConditionsAcceptedBy = userInfoOkta?.preferred_username || "_";
    soldToList[soldToIndex].termsAndConditionsTimestamp = new Date().toISOString();
  }
  //update soldto list
  eventProviderInstance.emit(eventProvider.EventChannel.soldToList, soldToList);

  if (changeSelectedSoldTo) {
    //change selected sold-to
    setLastSelectedSoldTo(soldToId, true);
  }
};
