import { eventProvider } from "@mercury/common";
export type CookiePolicyModalInfo = {
  show: boolean;
  showClose?: boolean;
  title?: string;
  description?: string;
};

export const openCookiePolicyModal = (cookiePolicyModalInfo: CookiePolicyModalInfo) => {
  const eventProviderInstance = eventProvider.getEventProvider();
  eventProviderInstance.emit(eventProvider.EventChannel.cookiePolicyModal, cookiePolicyModalInfo);
};