import App from "@/App.vue";
import { cmsUtility, cookieUtility } from "@mercury/common";
import "@mercury/common/style";
import { OktaAuth } from "@okta/okta-auth-js";
import OktaVue, { OktaVueOptions, navigationGuard } from "@okta/okta-vue";
import { oktaConfig } from "@utils/auth";
import { createPinia } from "pinia";
import { registerSW } from "virtual:pwa-register";
import "virtual:uno.css";
import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { ROUTING, maintenanceRoute, routes } from "./routing/routes";
import "./style/main.scss";

registerSW({
  immediate: true,
  onRegisterError(error) {},
});

//prevent Okta CORS error in localhost
if (import.meta.env.MODE === "development") {
  const { fetch: originalFetch } = window;
  window.fetch = (...args) => {
    let [resource, config] = args;
    // console.log("resource", { resource, config, args });
    // POINT TO DEV
    //const oktaUrl = "https://account-vfportal-emea-dev.vfc.com";
    // POINT TO QA
    const oktaUrl = "https://account-vfportal-qa.vfc.com";
    if (resource?.startsWith(oktaUrl)) {
      resource = resource.replace(oktaUrl, "http://localhost:8080/okta_api");
    }
    return originalFetch(resource, config);
  };
}

//pinia setup
const pinia = createPinia();

const showMaintenance = window.globalEnvVariables?.SHOW_MAINTENANCE;

//routing setup
export const router = createRouter({
  history: createWebHistory(),
  routes: showMaintenance ? maintenanceRoute : routes || [],
});
//handle redirect to login page if user is not authenticated
router.beforeEach(navigationGuard);

//okta setup
const oktaAuth = new OktaAuth(oktaConfig.oidc);
const oktaOptions: OktaVueOptions = {
  oktaAuth,
  onAuthRequired: () => {
    router.push(ROUTING.login.url);
  },
  onAuthResume: () => {
    router.push(ROUTING.login.url);
  },
};

if (window.location?.search.includes(cmsUtility.SESSION_STORAGE_PREVIEW_CMS + "=true")) {
  //activate CMS preview
  // localStorage.setItem(cmsUtility.SESSION_STORAGE_PREVIEW_CMS, "true");
  cookieUtility.createCookie(
    cmsUtility.SESSION_STORAGE_PREVIEW_CMS,
    "true",
    6,
    false,
    true,
    "None",
  );
  console.log("Activating CMS preview");

  // Create a script element for jQuery
  const jqueryScript = document.createElement("script");
  //TODO set correct url
  jqueryScript.src = "https://qu.leonardo.essilorluxottica.com/preview/jquery-3.3.1.min.js";
  jqueryScript.type = "text/javascript";
  jqueryScript.defer = true;

  jqueryScript.onload = function () {
    // Once jQuery is loaded, load coremedia.preview.js and placementHighlighting.js
    const coreMediaScript = document.createElement("script");
    //TODO set correct url
    coreMediaScript.src = "https://qu.leonardo.essilorluxottica.com/preview/coremedia.preview.js";
    coreMediaScript.type = "text/javascript";
    coreMediaScript.defer = true;

    const placementHighlightScript = document.createElement("script");
    //TODO set correct url
    placementHighlightScript.src =
      "https://qu.leonardo.essilorluxottica.com/preview/placementHighlighting.js";
    placementHighlightScript.type = "text/javascript";
    placementHighlightScript.defer = true;

    // Append the script elements to the document
    document.head.appendChild(coreMediaScript);
    document.head.appendChild(placementHighlightScript);
  };
  // Append the script element to the document
  document.body.appendChild(jqueryScript);
} else {
  //no CMS preview
  if (cookieUtility.getCookie(cmsUtility.SESSION_STORAGE_PREVIEW_CMS)) {
    //if the CMS preview local storage is set --> remove it
    cookieUtility.removeCookie(cmsUtility.SESSION_STORAGE_PREVIEW_CMS);
  }
  // if (localStorage.getItem(cmsUtility.SESSION_STORAGE_PREVIEW_CMS)) {
  //   //if the CMS preview local storage is set --> remove it
  //   localStorage.removeItem(cmsUtility.SESSION_STORAGE_PREVIEW_CMS);
  // }
}

createApp(App).use(pinia).use(router).use(OktaVue, oktaOptions).mount("#mercury-app");

// }
