<template class="">
  <vf-modal
    name="cookie-policy"
    :open="show"
    :show-close="false"
    class="flex! flex-col! gap-0! mx-auto! my-auto! justify-center! h-100vh!"
    :disable-click-trigger-self="true"
    @close="closeModal"
  >
    <template #content>
      <vf-loading :hide-spinner="true" :is-loading="false">
        <template #loader>
          <vf-skeleton width-class="w-400px" height-class="h-324px" />
        </template>
        <div>
          <div class="flex flex-col gap-lg">
            <div class="flex justify-between">
              <h1 class="text-lg font-bold c-gray-900 w-full sm:pr-35px">
                {{ translate("cookie_policy.title") }}
              </h1>
            </div>
            <p v-dompurify-html="description" class="text-sm regular c-gray-600" />
            <p class="text-sm regular c-gray-600">{{ translate("cookie_policy.accept") }}</p>
          </div>
          <div class="flex flex-col mt-4xl">
            <div class="flex gap-xl items-stretch">
              <vf-button
                variant="secondary"
                class="w-full! h-auto!"
                :label="translate('cookie_policy.do_not_accept')"
                :aria-label="translate('cookie_policy.do_not_accept')"
                @click="doNotAcceptCookies"
              />
              <vf-button
                variant="primary"
                class="w-full! h-auto!"
                :label="translate('cookie_policy.accept_all')"
                :aria-label="translate('cookie_policy.accept_all')"
                @click="acceptCookies"
              />
            </div>
          </div>
        </div>
      </vf-loading>
    </template>
  </vf-modal>
</template>
<script setup lang="ts">
import {
  analyticsOptin,
  analyticsOptout,
  cookieUtility,
  getLegalDocumentsCms,
  translation,
  useSelectedSoldTo,
} from "@mercury/common";
import { VfButton } from "@mercury/common/VfButton";
import { VfLoading } from "@mercury/common/VfLoading";
import { VfModal } from "@mercury/common/VfModal";
import { VfSkeleton } from "@mercury/common/VfSkeleton";
import { computed, ref, watch } from "vue";
import { buildVueDompurifyHTMLDirective } from "vue-dompurify-html";

const props = withDefaults(
  defineProps<{
    show: boolean;
    closeModal: () => void;
  }>(),
  { closeModal: () => {} },
);
const vDompurifyHtml = buildVueDompurifyHTMLDirective({ default: { ADD_ATTR: ["target"] } });
const { translate, lng } = translation.useTranslation();
const selectedSoldTo = useSelectedSoldTo();
const legalDocumentMap = ref<Record<string, string>>({});

const description = computed(() =>
  translate("cookie_policy.description")
    .replace(
      "{{privacy}}",
      `<a class="w-fit bold appearance-none underline cursor-pointer text-sm text-VF-600 hover:text-VF-700 stroke-VF-600 hover:stroke-VF-700" href="${legalDocumentMap.value["privacy-policy"] ? legalDocumentMap.value["privacy-policy"] : "javascript:void(0)"}" target="_blank" ${legalDocumentMap.value["privacy-policy"] ? "" : "disabled=true"}>${translate("cookie_policy.privacy")}</a>`,
    )
    .replace(
      "{{policy}}",
      `<a class="w-fit bold appearance-none underline cursor-pointer text-sm text-VF-600 hover:text-VF-700 stroke-VF-600 hover:stroke-VF-700" href="${legalDocumentMap.value["cookie-policy"] ? legalDocumentMap.value["cookie-policy"] : "javascript:void(0)"}" target="_blank" ${legalDocumentMap.value["cookie-policy"] ? "" : "disabled=true"}>${translate("cookie_policy.policy")}</a>`,
    ),
);

const accepted = ref(false);
const acceptCookies = () => {
  // Your code to accept cookies
  accepted.value = true;
  cookieUtility.setCookiePolicy(true);
  props.closeModal();

  analyticsOptin();
};

const doNotAcceptCookies = () => {
  // Your code to reject cookies
  accepted.value = false;
  cookieUtility.setCookiePolicy(false);
  props.closeModal();

  analyticsOptout();
};

watch(
  [lng, selectedSoldTo],
  (params) => {
    const [lng, selectedSoldTo] = params;
    if (lng && selectedSoldTo?.customerId) {
      getLegalDocumentsCms({
        language: lng,
        customerId: selectedSoldTo?.customerId,
      })
        .then((result: Record<string, string>) => {
          console.log("result", { result });
          legalDocumentMap.value = result;
        })
        .catch(() => {});
    }
  },
  { immediate: true },
);
</script>

<style scoped>
.vf-modal .vf-modal__content {
  margin: 0;
}
.vf-modal__header,
.vf-modal__description {
  margin-bottom: 0;
}
</style>
