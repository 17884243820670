import { LoginCallback } from "@okta/okta-vue";
import { Component } from "vue";
import { RouteRecordRaw } from "vue-router";
const LoginPage = () => import("@pages/LoginPage.vue");
const ForgotPasswordPage = () => import("@pages/ForgotPasswordPage.vue");
const FaqsTicketsPage = () => import("@pages/FaqsTicketsPage.vue");
const DashboardPage = () => import("@pages/DashboardPage.vue");
const AccountSettingsPage = () => import("@pages/AccountSettingsPage.vue");
const NotificationsPage = () => import("@pages/NotificationsPage.vue");
const WaitingPage = () => import("@pages/WaitingPage.vue");
const MaintenancePage = () => import("@pages/MaintenancePage.vue");
const InternalUserPage = () => import("@pages/InternalUserPage.vue");
const AcceptTermsConditionsPage = () => import("@pages/AcceptTermsConditionsPage.vue");
const SoldToPage = () => import("@pages/SoldToPage.vue");

const requiresAuthOptions = {
  meta: {
    requiresAuth: true,
  },
};
const notRequiresAuthOptions = {
  meta: {
    requiresAuth: false,
  },
};

export interface RoutingInterface {
  path: string;
  url: string;
  name: string;
  labelKey: string;
  component: Component;
  sidebarSelection?:
    | "Dashboard"
    | "Shop Brands"
    | "Order History"
    | "Reports"
    | "Notifications"
    | "Tickets"
    | "Help"
    | "Account Settings";
  children?: { [key: string]: RoutingInterface };
}

interface RoutingMapInterface {
  [key: string]: RoutingInterface;
}

export const ROUTING: RoutingMapInterface = {
  dashboard: {
    path: "/dashboard", //all /dashboard subroutes
    url: "/dashboard", //all /dashboard subroutes
    name: "Dashboard",
    labelKey: "sidebar.dashboard",
    component: DashboardPage,
  },
  brand: {
    path: "/brands/:pathMatch(.*)*",
    url: "/brands",
    name: "Shop Brands",
    labelKey: "sidebar.brand",
    component: AccountSettingsPage,
  },
  shopBrand: {
    path: "/brands/shop/:brandId",
    url: "/brands/shop/:brandId",
    name: "Shop brand page",
    labelKey: "shop_b2b.title",
    component: AccountSettingsPage,
  },
  updates: {
    path: "/updates/:pathMatch(.*)*",
    url: "/updates",
    name: "Notifications",
    labelKey: "sidebar.notifications",
    sidebarSelection: "Notifications",
    component: NotificationsPage,
  },
  orderHistory: {
    path: "/order-history",
    url: "/order-history",
    name: "Order History",
    labelKey: "sidebar.order_history",
    component: AccountSettingsPage,
  },
  report: {
    path: "/reports/:pathMatch(.*)*",
    url: "/reports",
    name: "Reports",
    labelKey: "sidebar.reports",
    component: AccountSettingsPage,
  },
  tickets: {
    path: "/ticket/:pathMatch(.*)*",
    url: "/ticket/history",
    name: "Tickets",
    labelKey: "sidebar.tickets",
    component: FaqsTicketsPage,
  },
  help: {
    path: "/faqs/:pathMatch(.*)*",
    url: "/faqs",
    name: "Help",
    labelKey: "sidebar.help",
    component: FaqsTicketsPage,
  },
  cookiePolicy: {
    path: "/cookie-policy",
    url: "/cookie-policy",
    name: "Cookie Policy",
    labelKey: "",
    component: AccountSettingsPage,
  },
  onBehalf: {
    path: "/on-behalf/:pathMatch(.*)*",
    url: "/on-behalf",
    name: "On Behalf",
    labelKey: "sidebar.on_behalf",
    component: AccountSettingsPage,
  },
  accountSettings: {
    path: "/account/:pathMatch(.*)*",
    url: "/account",
    name: "Account",
    labelKey: "",
    component: AccountSettingsPage,
  },
  documents: {
    path: "/account/documents",
    url: "/account/documents",
    name: "Documents",
    labelKey: "documents.title",
    component: AccountSettingsPage,
  },
  login: {
    path: "/login",
    url: "/login",
    name: "Login",
    labelKey: "",
    component: LoginPage,
  },
  forgotPassword: {
    path: "/forgot-password",
    url: "/forgot-password",
    name: "ForgotPassword",
    labelKey: "",
    component: ForgotPasswordPage,
  },
  waiting: {
    path: "/waiting",
    url: "/waiting",
    name: "Waiting",
    labelKey: "",
    component: WaitingPage,
  },

  maintenance: {
    path: "/maintenance",
    url: "/maintenance",
    name: "Maintenance",
    labelKey: "",
    component: MaintenancePage,
  },
  order: {
    path: "/order",
    url: "/order",
    name: "order",
    labelKey: "order.title",
    component: InternalUserPage,
  },
  ecommerce: {
    path: "/ecommerce",
    url: "/ecommerce",
    name: "ecommerce",
    labelKey: "ecommerce.title",
    component: InternalUserPage,
  },
  inquiry: {
    path: "/inquiry",
    url: "/inquiry",
    name: "inquiry",
    labelKey: "inquiry.title",
    component: InternalUserPage,
  },
  returns: {
    path: "/returns",
    url: "/returns",
    name: "returns",
    labelKey: "internal.title_returns",
    component: InternalUserPage,
  },
  warranty: {
    path: "/warranty",
    url: "/warranty",
    name: "warranty",
    labelKey: "internal.title_warranty",
    component: InternalUserPage,
  },
  etaf: {
    path: "/etaf",
    url: "/etaf",
    name: "Etaf",
    labelKey: "internal.title_etaf",
    component: InternalUserPage,
  },
  etafConfig: {
    path: "/etaf-config",
    url: "/etaf-config",
    name: "EtafConfiguration",
    labelKey: "internal.title_etafConfiguration",
    component: InternalUserPage,
  },
  b2bCms: {
    path: "/b2b-cms",
    url: "/b2b-cms",
    name: "B2bCms",
    labelKey: "internal.title_b2bCms",
    component: InternalUserPage,
  },
  mmConfig: {
    path: "/mm-config",
    url: "/mm-config",
    name: "MMConfig",
    labelKey: "internal.title_mmConfig",
    component: InternalUserPage,
  },
  sapControlReport: {
    path: "/sap-control-report",
    url: "/sap-control-report",
    name: "SapControlReport",
    labelKey: "internal.title_sapControlReport",
    component: InternalUserPage,
  },
  termsConditions: {
    path: "/terms-conditions",
    url: "/terms-conditions",
    name: "Terms & Conditions",
    labelKey: "",
    component: AccountSettingsPage,
  },
  acceptTermsConditions: {
    path: "/accept-terms-conditions/:soldToId",
    url: "/accept-terms-conditions/:soldToId",
    name: "Standard Terms & Conditions",
    labelKey: "acceptTermsConditions",
    component: AcceptTermsConditionsPage,
  },
  soldTo: {
    path: "/sold-to",
    url: "/sold-to",
    name: "Sold to",
    labelKey: "Sold to",
    component: SoldToPage,
  },
} as const;

export const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: ROUTING.dashboard.path,
    name: ROUTING.dashboard.name,
    component: ROUTING.dashboard.component,
    ...requiresAuthOptions,
  },
  /*{
    path: ROUTING.shopBrand.path,
    name: ROUTING.shopBrand.name,
    component: ROUTING.shopBrand.component,
    ...requiresAuthOptions,
  },*/
  {
    path: ROUTING.brand.path,
    name: ROUTING.brand.name,
    component: ROUTING.brand.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.orderHistory.path,
    name: ROUTING.orderHistory.name,
    component: ROUTING.orderHistory.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.report.path,
    name: ROUTING.report.name,
    component: ROUTING.report.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.updates.path,
    name: ROUTING.updates.name,
    component: ROUTING.updates.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.tickets.path,
    name: ROUTING.tickets.name,
    component: ROUTING.tickets.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.help.path,
    name: ROUTING.help.name,
    component: ROUTING.help.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.cookiePolicy.path,
    name: ROUTING.cookiePolicy.name,
    component: ROUTING.cookiePolicy.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.onBehalf.path,
    name: ROUTING.onBehalf.name,
    component: ROUTING.onBehalf.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.accountSettings.path,
    name: ROUTING.accountSettings.name,
    component: ROUTING.accountSettings.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.documents.path,
    name: ROUTING.documents.name,
    component: ROUTING.documents.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.login.path,
    name: ROUTING.login.name,
    component: ROUTING.login.component,
    ...notRequiresAuthOptions,
  },
  {
    //used just by Okta
    path: "/login/callback",
    component: LoginCallback,
    ...notRequiresAuthOptions,
  },
  {
    path: ROUTING.forgotPassword.path,
    name: ROUTING.forgotPassword.name,
    component: ROUTING.forgotPassword.component,
    ...notRequiresAuthOptions,
  },
  {
    path: ROUTING.waiting.path,
    name: ROUTING.waiting.name,
    component: ROUTING.waiting.component,
    ...requiresAuthOptions,
    meta: {
      ...requiresAuthOptions.meta,
      hideCookieModal: true,
    },
  },

  {
    path: ROUTING.order.path,
    name: ROUTING.order.name,
    component: ROUTING.order.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.ecommerce.path,
    name: ROUTING.ecommerce.name,
    component: ROUTING.ecommerce.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.inquiry.path,
    name: ROUTING.inquiry.name,
    component: ROUTING.inquiry.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.returns.path,
    name: ROUTING.returns.name,
    component: ROUTING.returns.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.warranty.path,
    name: ROUTING.warranty.name,
    component: ROUTING.warranty.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.etaf.path,
    name: ROUTING.etaf.name,
    component: ROUTING.etaf.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.etafConfig.path,
    name: ROUTING.etafConfig.name,
    component: ROUTING.etafConfig.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.b2bCms.path,
    name: ROUTING.b2bCms.name,
    component: ROUTING.b2bCms.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.sapControlReport.path,
    name: ROUTING.sapControlReport.name,
    component: ROUTING.sapControlReport.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.mmConfig.path,
    name: ROUTING.mmConfig.name,
    component: ROUTING.mmConfig.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.termsConditions.path,
    name: ROUTING.termsConditions.name,
    component: ROUTING.termsConditions.component,
    ...requiresAuthOptions,
  },
  {
    path: ROUTING.acceptTermsConditions.path,
    name: ROUTING.acceptTermsConditions.name,
    component: ROUTING.acceptTermsConditions.component,
    ...requiresAuthOptions,
    meta: {
      ...requiresAuthOptions.meta,
      hideCookieModal: true,
    },
  },
  {
    path: ROUTING.soldTo.path,
    name: ROUTING.soldTo.name,
    component: ROUTING.soldTo.component,
    ...requiresAuthOptions,
    meta: {
      ...requiresAuthOptions.meta,
      hideCookieModal: true,
    },
  },
  { path: "/", redirect: ROUTING.dashboard.url },
  { path: "/:catchAll(.*)", redirect: ROUTING.dashboard.url },
];

export const maintenanceRoute: Readonly<RouteRecordRaw[]> = [
  {
    path: ROUTING.maintenance.path,
    name: ROUTING.maintenance.name,
    component: ROUTING.maintenance.component,
  },
  { path: "/", redirect: ROUTING.maintenance.url },
  { path: "/:catchAll(.*)", redirect: ROUTING.maintenance.url },
];

// export const standardTermsConditionsRoute: Readonly<RouteRecordRaw[]> = [
//   {
//     path: ROUTING.acceptTermsConditions.path,
//     name: ROUTING.acceptTermsConditions.name,
//     component: ROUTING.acceptTermsConditions.component,
//   },
//   { path: "/", redirect: ROUTING.acceptTermsConditions.url },
//   { path: "/:catchAll(.*)", redirect: ROUTING.acceptTermsConditions.url },
// ];
